/* latin-ext */
@font-face {
  font-family: '__Karla_1946d8';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(/_next/static/media/fc30455341b29057-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Karla_1946d8';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(/_next/static/media/ba5f7ed75552623e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Karla_Fallback_1946d8';src: local("Arial");ascent-override: 89.55%;descent-override: 24.61%;line-gap-override: 0.00%;size-adjust: 102.40%
}.__className_1946d8 {font-family: '__Karla_1946d8', '__Karla_Fallback_1946d8';font-style: normal
}

